<template>
  <div class="art-list">
    <el-row>
      <el-col :span="4">
        <div class="operator">
          <el-button type="success" size="mini" plain @click="createFormVisible = true">创建资源报告</el-button>
        </div>
      </el-col>
      <el-col :span="10">
        <div class="filter">
          <div class="tags">
            标签筛选：
            <el-select
              size="small"
              ref="tags"
              v-model="tagsSelected"
              multiple
              filterable
              default-first-option
              placeholder="全部"
              clearable
              @change="tagsSelectChange"
            >
              <el-option v-for="item in tagList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </div>
        </div>
      </el-col>
      <el-col class="text-right" :span="10">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          layout="total, prev, pager, next"
          :total="totalCount"
        >
        </el-pagination>
      </el-col>
    </el-row>
    <ArtCard v-for="(item, i) in artList" :key="i" :data="item" />
    <el-dialog title="创建报告" :visible.sync="createFormVisible" width="400px" :close-on-click-modal="false">
      <el-form :model="resourceForm" ref="resourceForm">
        <el-form-item label="资源名称" prop="name" :rules="[{ required: true, message: '名称不能为空', trigger: 'blur' }]">
          <el-input v-model.trim="resourceForm.name" maxlength="20" show-word-limit></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="createFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitResourceForm">创 建</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<style lang="less">
.art-list {
  .operator {
    margin-bottom: 20px;
  }

  .filter {
    .tags {
      .el-tag {
        margin-right: 10px;
        cursor: pointer;
      }
    }
  }
}
</style>

<script>
import ArtCard from "./ArtCard";
export default {
  name: "art-list",
  components: {
    ArtCard
  },
  data() {
    return {
      artList: [],
      tagList: [],
      tagsSelected: [],
      createFormVisible: false,
      resourceForm: { name: "" },
      currentPage: 1,
      pageSize: 10,
      totalCount: 0
    };
  },
  mounted() {
    this.getArts();
    this.getTags();
  },
  methods: {
    // 获取资源列表
    getArts(params = {}) {
      Object.assign(params, {
        pageSize: this.pageSize,
        pageNum: this.currentPage
      });
      this.$apis.getResources(params).then(res => {
        const data = res.data;
        this.artList = data.data;
        this.totalCount = data.totalCount;
      });
    },
    // 获取标签列表
    getTags() {
      this.$apis.getTags().then(res => {
        let data = res.data;
        this.tagList = data;
      });
    },
    // 表单提交
    submitResourceForm() {
      this.$refs["resourceForm"].validate(valid => {
        if (valid) {
          this.$apis.createResource(this.resourceForm).then(res => {
            let data = res.data;
            this.$message.success("创建成功");
            this.$router.push(`/art/${data.id}`);
          });
        }
      });
    },
    // 标签筛选
    tagsSelectChange(val) {
      this.getArts({ tags: val });
    },

    // 翻页
    handleCurrentChange(val) {
      this.getArts();
    }
  },
  watch: {}
};
</script>
