/**
 * 接口请求
 */
import http from "./index";
import qs from "qs";

export function get(url: string, params?: object) {
  return http.get(url, { params: params });
}

export function post(url: string, params?: object) {
  return http.request({
    url: url,
    method: "post",
    data: qs.stringify(params, { arrayFormat: "brackets" })
  });
  // return http.post(url, qs.stringify({ params }, { arrayFormat: "brackets" }));
}

export function patch(url: string, params?: object) {
  return http.request({
    url: url,
    method: "patch",
    data: qs.stringify(params, { arrayFormat: "repeat" })
  });
}

export function myDelete(url: string, params?: object) {
  return http.request({
    url: url,
    method: "delete",
    data: qs.stringify(params, { arrayFormat: "brackets" })
  });
}

/**
 * 请求JWT Token
 */
export function getJwtToken() {
  return get("/access-token/");
}

/**
 * 登出
 */
export function logout() {
  return get("/logout/");
}

/**
 * 读取文件
 * @param path 文件路径
 */
export function readFile(path: string) {
  return "/api/upload/read" + path;
}

/**
 * 请求用户列表
 */
export function getUsers() {
  return get("/api/user/");
}

/**
 * 统计打卡时长
 * @param start
 * @param end
 */
export function countDuration(start: string, end: string, type = "sum", interval = "day") {
  return get("/api/attend/record/count_duration/", {
    start: start,
    end: end,
    type: type,
    interval: interval
  });
}

/**
 * 跨夜补卡
 */
export function repairNightClockin(date: string) {
  return post("/api/attend/record/repair_night/", { date: date });
}

/**
 * 获取项目列表
 */
export function getProjects() {
  return get("/api/task/project/");
}

/**
 * 导入项目数据
 */
export function importProjects() {
  return post("/api/task/project/import_from_api/");
}

/**
 * 导入任务工时数据
 * @param start
 */
export function importWorkloads(start = "") {
  return post("/api/task/workload/import_from_api/", { start: start });
}

/**
 * 获取任务列表
 */
export function getTasks() {
  return get("/api/task/task/");
}

/**
 * 创建周会报告
 */
export function createMeeting(start: string, end: string, agenda = "", meeting_summary = "") {
  return post("/api/task/meeting/", { start: start, end: end, agenda: agenda, meeting_summary: meeting_summary });
}

/**
 * 修改周会报告
 */
export function patchMeeting(id: number, params: object) {
  return patch(`/api/task/meeting/${id}/`, params);
}

/**
 * 删除周会报告
 */
export function deleteMeeting(id: number) {
  return myDelete(`/api/task/meeting/${id}/`);
}

/**
 * 获取周会报告列表
 */
export function getMeetings(pageNum = 1, pageSize = 20) {
  return get("/api/task/meeting/", { pageNum: pageNum, pageSize: pageSize });
}

/**
 * 获取周会报告
 */
export function getMeeting(id: number) {
  return get(`/api/task/meeting/${id}/`);
}

/**
 * 获取美术报告列表
 */
export function getResources(params = {}) {
  return get("/api/art/resource/", params);
}

/**
 * 获取美术报告详情
 */
export function getResource(id: number) {
  return get(`/api/art/resource/${id}/`);
}

/**
 * 创建美术报告
 */
export function createResource(params: object) {
  return post(`/api/art/resource/`, params);
}

/**
 * 修改资源
 */
export function patchResource(id: number, params: object) {
  return patch(`/api/art/resource/${id}/`, params);
}

/**
 * 删除资源
 */
export function deleteResource(id: number) {
  return myDelete(`/api/art/resource/${id}/`);
}

/**
 * 创建报告阶段
 */
export function createStage(params: object) {
  return post(`/api/art/stage/`, params);
}

/**
 * 删除阶段
 */
export function deleteStage(id: number) {
  return myDelete(`/api/art/stage/${id}/`);
}

/**
 * 获取报告版本
 */
export function getVersion(id: number) {
  return get(`/api/art/version/${id}/`);
}

/**
 * 创建报告版本
 */
export function createVersion(params: object) {
  return post(`/api/art/version/`, params);
}

/**
 * 修改报告版本
 */
export function patchVersion(id: number, params: object) {
  return patch(`/api/art/version/${id}/`, params);
}

/**
 * 删除报告版本
 */
export function deleteVersion(id: number) {
  return myDelete(`/api/art/version/${id}/`);
}

/**
 * 发送版本提醒
 */
export function alertVersion(id: number) {
  return post(`/api/art/version/${id}/alert/`);
}

/**
 * 获取报告标签列表
 */
export function getTags() {
  return get(`/api/art/tag/`);
}

/**
 * 获取报告标签列表
 */
export function createTag(params: object) {
  return post(`/api/art/tag/`, params);
}
