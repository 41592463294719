<template>
  <div class="dashboard">
    <MyQuillEditor v-model="value" />
  </div>
</template>

<script>
import MyQuillEditor from "@/components/MyQuillEditor";
export default {
  name: "dashboard",
  components: {
    MyQuillEditor
  },
  data() {
    return {
      value: "hello"
    };
  },
  mounted() {
    // this.$api.getJwtToken().then(response => {
    //   console.log("return 1");
    // });
    // this.$api.getJwtToken().then(response => {
    //   console.log("return 2");
    // });
  },
  watch: {
    value: {
      handler: function(val) {
        console.log("baba-text:", val);
        return val;
      },
      immediate: true
    }
  }
};
</script>
