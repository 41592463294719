<template>
  <div class="attendance">
    <el-form :inline="true" :model="filterForm" size="mini">
      <el-form-item>
        <el-button @click="clockinBtnEvent" type="danger">跨夜补卡</el-button>
      </el-form-item>
      <el-form-item label="">
        <el-switch v-model="filterForm.stack" active-text="堆叠" inactive-text="分开"> </el-switch>
      </el-form-item>
      <el-form-item label="时间范围">
        <el-date-picker
          v-model="filterForm.daterange"
          type="daterange"
          align="right"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          :editable="false"
          :clearable="false"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="间隔">
        <el-radio-group v-model="filterForm.interval">
          <el-radio-button v-for="item in intervals" :label="item.value" :key="item.value">
            {{ item.label }}
          </el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="类型">
        <el-radio-group v-model="filterForm.type">
          <el-radio-button v-for="item in countTypes" :label="item.value" :key="item.value">
            {{ item.label }}
          </el-radio-button>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div id="chart"></div>
    <el-dialog title="提示" :visible.sync="clockinVisible" width="30%">
      <div>
        <el-date-picker v-model="clockinDate" type="date" placeholder="选择日期"> </el-date-picker>
      </div>
      <p>
        熬夜有害身心，即将生成两条打卡记录：
      </p>
      <p class="text-danger" v-html="clockinTip"></p>
      <p>
        重复补卡将生成多条记录，是否继续？
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="clockinVisible = false">取 消</el-button>
        <el-button type="primary" @click="clockinNight">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<style lang="less" scoped>
.attendance {
  #chart {
    width: 100%;
    height: 400px;
  }

  .el-form {
    display: flex;
    justify-content: space-around;
  }
}
</style>

<script>
export default {
  name: "attendance",
  components: {},
  data() {
    return {
      countTypes: [
        { value: "sum", label: "总时长" },
        { value: "average", label: "平均时长" }
      ],
      intervals: [
        { value: "day", label: "日" },
        { value: "month", label: "月" },
        { value: "year", label: "年" }
      ],
      filterForm: {
        daterange: [this.$moment().subtract(7, "days"), this.$moment()],
        type: "sum",
        interval: "day",
        stack: true
      },
      durationChart: null,
      pickerOptions: {
        firstDayOfWeek: 1,
        disabledDate(time) {
          // 大于当前日期不能选
          return time.getTime() >= new Date();
        },
        shortcuts: [
          {
            text: "最近一周",
            onClick: picker => {
              picker.$emit("pick", [this.$moment().subtract(7, "days"), this.$moment()]);
            }
          },
          {
            text: "当月",
            onClick: picker => {
              picker.$emit("pick", [this.$moment().startOf("month"), this.$moment()]);
            }
          },
          {
            text: "上一个月",
            onClick: picker => {
              picker.$emit("pick", [
                this.$moment()
                  .subtract(1, "months")
                  .startOf("month"),
                this.$moment()
                  .subtract(1, "months")
                  .endOf("month")
              ]);
            }
          },
          {
            text: "今年",
            onClick: picker => {
              picker.$emit("pick", [this.$moment().startOf("year"), this.$moment()]);
            }
          }
        ]
      },
      clockinVisible: false,
      clockinDate: "",
      clockinTip: ""
    };
  },
  mounted() {
    // this.durationChart = this.$echarts.init(document.getElementById("chart"));
  },
  methods: {
    /**
     * 更新时长统计
     */
    updateDurationCount() {
      let start = this.$moment(this.filterForm.daterange[0]).format("YYYY-MM-DD");
      let end = this.$moment(this.filterForm.daterange[1]).format("YYYY-MM-DD");
      this.$apis.countDuration(start, end, this.filterForm.type, this.filterForm.interval).then(res => {
        const data = res.data;
        const users = data.users;
        const userLength = Object.keys(users).length;
        const result = data.result;
        let series = [];
        let timeMap = {};
        for (const userId in result) {
          series.push({
            name: users[userId],
            data: result[userId]
          });
          for (let i = 0; i < result[userId].length; i++) {
            if (Object.prototype.hasOwnProperty.call(timeMap, result[userId][i][0])) {
              timeMap[result[userId][i][0]].push(result[userId][i][1]);
            } else {
              timeMap[result[userId][i][0]] = [result[userId][i][1]];
            }
          }
        }
        // 计算平均值
        if (this.filterForm.type == "average") {
          let avgSeries = [];

          for (const d in timeMap) {
            let sum = 0;
            timeMap[d].forEach(function(val) {
              sum += val;
            }, 0);
            avgSeries.push([parseInt(d), sum / userLength]);
          }
          avgSeries.sort((x, y) => {
            return y[0] - x[0];
          });
          series.push({
            name: "平均值",
            type: "line",
            color: "#AA0000",
            data: avgSeries
          });
        }
        this.createDurationChart(series);
      });
    },

    /**
     * 创建时长统计图表
     */
    createDurationChart(series) {
      this.durationChart = this.$highcharts.chart("chart", {
        chart: {
          type: "column"
        },
        title: {
          text: "打卡总时长统计"
        },
        subtitle: {
          text: "数据来源：飞书打卡记录，每日上午4:00定时导入"
        },
        plotOptions: {
          series: {
            pointPadding: 0,
            // groupPadding: 0,
            borderWidth: 0,
            shadow: false
          },
          column: {
            stacking: this.filterForm.stack ? "normal" : undefined,
            dataLabels: {
              enabled: true
            }
          },
          line: {
            dataLabels: {
              enabled: true,
              format: "{y:.2f}",
              color: "#AA0000",
              y: 20,
              x: 25
            }
          }
        },
        tooltip: {
          valueDecimals: 2,
          dateTimeLabelFormats: {
            millisecond: "%H:%M:%S.%L",
            second: "%H:%M:%S",
            minute: "%H:%M",
            hour: "%H:%M",
            day: "%m-%d",
            week: "%m-%d",
            month: "%Y-%m",
            year: "%Y"
          }
        },
        xAxis: {
          type: "datetime",
          dateTimeLabelFormats: {
            millisecond: "%H:%M:%S.%L",
            second: "%H:%M:%S",
            minute: "%H:%M",
            hour: "%H:%M",
            day: "%m-%d",
            week: "%m-%d",
            month: "%Y-%m",
            year: "%Y"
          }
        },
        time: {
          useUTC: false
        },
        yAxis: {
          title: {
            text: "打卡时长"
          },
          stackLabels: {
            // 堆叠数据标签
            enabled: true
          }
        },
        series: series
      });
    },

    /**
     * 跨夜补卡
     */
    clockinBtnEvent() {
      this.clockinDate = this.$moment();
      this.clockinVisible = true;
    },
    clockinNight() {
      let date = this.$moment(this.clockinDate).format("YYYY-MM-DD");
      this.$apis.repairNightClockin(date).then(() => {
        this.clockinVisible = false;
        this.$message.success("补卡成功！");
      });
    }
  },
  watch: {
    filterForm: {
      handler: function() {
        this.updateDurationCount();
      },
      deep: true,
      immediate: true
    },
    clockinDate: {
      handler: function(val) {
        this.clockinTip = `${this.$moment(val)
          .subtract(1, "days")
          .format("YYYY-MM-DD 23:59:59")} <br /> ${this.$moment(val).format("YYYY-MM-DD 00:00:00")}`;
      }
    }
  }
};
</script>
