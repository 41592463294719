/**
 * 用户
 */
export class User {
  id: number;
  username: string;
  firstName: string;
  email: string;
  avatar: string;

  constructor(obj: any) {
    obj = obj || {};
    this.id = obj.id || obj.user_id;
    this.username = obj.username;
    this.firstName = obj.first_name || "";
    this.email = obj.email || "";
    this.avatar = obj.avatar || "";
  }
}
