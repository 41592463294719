import Vue from "vue";
import VueRouter, { Route, RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import Dashboard from "../views/Dashboard.vue";
import Attendance from "../views/Attendance.vue";
import Meeting from "../views/meeting/Meeting.vue";
import ArtList from "../views/art/List.vue";
import ArtDetail from "../views/art/Detail.vue";
import { getJwtToken } from "../common/http/apis";
import { Storage } from "../common/storage";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    component: Home,
    children: [
      {
        path: "/",
        redirect: { name: "meeting" }
      },
      {
        path: "/dashboard",
        name: "dashboard",
        component: Dashboard
      },
      {
        path: "meeting",
        name: "meeting",
        component: Meeting
      },
      {
        path: "attend",
        name: "attendance",
        component: Attendance
      },
      {
        path: "art",
        name: "art",
        component: ArtList
      },
      {
        path: "/art/:id/",
        name: "art-detail",
        component: ArtDetail
      }
    ]
  }
];

const router = new VueRouter({
  routes
});

router.beforeEach((to: Route, from: Route, next) => {
  getJwtToken().then(res => {
    const data = res.data;
    if (JSON.stringify(data) == "{}") {
      window.location.href = "/login?next=" + to.path;
      return false;
    }
    const token = data.jwt_token;
    if (token) {
      localStorage.setItem(Storage.jwtUserInfo, JSON.stringify(data));
    }
    next();
  });
});

export default router;
