<template>
  <div class="art-detail" v-if="data.id">
    <div class="header">
      <h2>
        <el-avatar size="small" :src="data.created_by_obj.avatar"></el-avatar>【美术资源报告{{ data.id }}】{{ data.name }}
        <TagSelect style="margin-left:30px;" v-model="data.tag_objs" :resourceId="data.id" />
      </h2>
      <div class="operator" v-if="!editMode">
        <el-button type="danger" icon="el-icon-delete" round @click="deleteResource">删除报告</el-button>
      </div>
    </div>
    <el-divider />
    <el-row>
      <el-col :span="5">
        <div class="stage-operator" v-if="!editMode">
          <el-button type="primary" size="mini" @click="createStageVisible = true" style="margin-right:10px">创建阶段</el-button>
          <el-popconfirm title="确定删除当前选中阶段吗？" @confirm="deleteStage">
            <el-button slot="reference" type="danger" size="mini">删除阶段</el-button>
          </el-popconfirm>
        </div>
        <el-tabs tab-position="left" v-model="stageSelected">
          <el-tab-pane v-for="(item, i) in data.stage_objs" :key="i" :label="item.name" :name="item.id + ''">
            <div class="version-operator" v-if="!editMode">
              <el-button
                type="success"
                icon="el-icon-plus"
                size="mini"
                round
                title="创建新内容"
                @click="
                  editMode = true;
                  versionSelected = '-1';
                "
              >
                创建新内容
              </el-button>
            </div>
            <el-timeline>
              <el-timeline-item
                v-for="(version, j) in item.versions"
                :key="j"
                :type="timelineType(version)"
                icon="el-icon-caret-right"
                size="large"
              >
                <span
                  class="version-item"
                  :class="{ 'text-green': versionSelected == version }"
                  @click="versionSelectEvent(version)"
                  >第 {{ item.versions.length - j }} 轮</span
                >
              </el-timeline-item>
            </el-timeline>
          </el-tab-pane>
        </el-tabs>
      </el-col>
      <el-col :span="19">
        <div class="content">
          <div class="version">
            <div class="version-header box-fixed" :class="{ top_fixed: operatorFixed }">
              <!-- <h2 class="text-success"><i class="el-icon-caret-right"></i>第 {{ stage.versions.length - j }} 轮</h2> -->
              <div v-if="editMode">
                <el-button type="success" size="mini" icon="el-icon-check" @click="saveVersion"> 保存</el-button>
                <el-button size="mini" @click="editMode = false" icon="el-icon-close">取消 </el-button>
              </div>
              <div v-else-if="versionData.id">
                <el-button type="primary" size="small" icon="el-icon-edit" plain @click="editMode = true">编辑</el-button>
                <el-button type="danger" size="small" icon="el-icon-delete" plain @click="deleteVersion">删除</el-button>
                <el-button
                  type="warning"
                  size="small"
                  icon="el-icon-bell"
                  :loading="alertLoading"
                  plain
                  round
                  @click="alertVersion"
                  >发送提醒</el-button
                >
              </div>
              <el-breadcrumb separator="|" v-if="versionData.id">
                <el-breadcrumb-item>
                  <small>
                    {{ versionData.updated_by_obj.first_name }} 更新于 {{ versionData.updated_on | moment("YYYY-M-D HH:mm:ss") }}
                  </small>
                </el-breadcrumb-item>
                <el-breadcrumb-item>
                  <small>
                    {{ versionData.created_by_obj.first_name }} 创建于 {{ versionData.created_on | moment("YYYY-M-D HH:mm:ss") }}
                  </small>
                </el-breadcrumb-item>
              </el-breadcrumb>
            </div>
            <div class="version-content">
              <el-input v-model="versionData.id" type="hidden"></el-input>
              <div>
                <h2>简介</h2>
                <MyQuillEditor
                  v-if="editMode"
                  v-model="versionData.intro"
                  :dir="editorDirName"
                  placeholder="简要描述当前资源背景与设计目的。如果是迭代轮次中，可简要描述主要修改内容"
                />
                <p class="ql-editor" v-else v-html="versionData.intro || '暂无内容'"></p>
              </div>
              <el-divider />
              <div>
                <h2>效果展示 <small>本次设计/开发最终效果，分多组图文展示；Blender模型可使用插件导出自动生成到这里</small></h2>
                <div v-if="sceneNoteList.length > 0">
                  <el-card v-for="(item, i) in sceneNoteList" :key="i" class="scene-card" :shadow="editMode ? 'hover' : 'nerver'">
                    <div>
                      <el-image
                        v-for="(img, j) in item.imgs"
                        :key="j"
                        :src="readImg(img)"
                        :preview-src-list="previewImgList"
                      ></el-image>
                    </div>
                    <div class="scene-name">
                      <b>{{ item.name }}</b>
                    </div>
                    <div class="ql-editor" v-html="item.desc"></div>
                    <div v-if="editMode" class="text-right">
                      <el-button
                        type="warning"
                        size="mini"
                        circle
                        icon="el-icon-edit"
                        title="编辑"
                        @click="editSceneEvent(item.name)"
                      ></el-button>
                      <el-button
                        type="danger"
                        size="mini"
                        circle
                        icon="el-icon-delete"
                        title="删除"
                        @click="delSceneEvent(item.name)"
                      ></el-button>
                    </div>
                  </el-card>
                </div>
                <div v-else-if="!editMode">暂无可展示内容</div>

                <el-row v-if="editMode" class="scene-note-form" :gutter="20">
                  <el-col :span="10">
                    <el-alert title="每组效果图最多只支持上传2张，多图请生成多组效果卡片" type="info" :closable="false" />
                    <el-upload
                      ref="sceneUpload"
                      action="/api/upload/file/"
                      :data="{ dir: editorDirName }"
                      :limit="2"
                      list-type="picture-card"
                      accept="image/gif,image/jpeg,image/jpg,image/png,image/svg"
                      :on-success="pictureUploadSuccess"
                      :on-remove="pictureRemove"
                      :file-list="sceneImgsList"
                      multiple
                    >
                      <i class="el-icon-plus"></i>
                    </el-upload>
                  </el-col>
                  <el-col :span="14">
                    <el-alert title="点击生成将生成一组效果卡片，图片名称相同的将覆盖" type="info" :closable="false" />
                    <el-form ref="sceneForm" :model="sceneForm" label-width="80px">
                      <el-form-item
                        label="图片名称"
                        prop="name"
                        :rules="[{ required: true, message: '名称不能为空', trigger: 'blur' }]"
                      >
                        <el-input
                          v-model.trim="sceneForm.name"
                          placeholder="请输入图片名称，如“图片 1-1”"
                          maxlength="20"
                          show-word-limit
                        ></el-input>
                      </el-form-item>
                      <el-form-item
                        label="图片描述"
                        prop="desc"
                        :rules="[{ required: true, message: '描述不能为空啦～', trigger: 'blur' }]"
                      >
                        <MyQuillEditor
                          v-model="sceneForm.desc"
                          :dir="editorDirName"
                          mode="simple"
                          placeholder="详细描述上传的图片意义"
                        />
                      </el-form-item>
                      <el-form-item>
                        <el-button type="primary" size="mini" @click="buildSceneForm">生成</el-button>
                        <el-button size="mini" @click="resetSceneForm">清空</el-button>
                      </el-form-item>
                    </el-form>
                  </el-col>
                </el-row>
              </div>
              <el-divider />
              <div>
                <h2>详细内容/其他内容</h2>
                <MyQuillEditor
                  v-if="editMode"
                  v-model="versionData.desc"
                  :dir="editorDirName"
                  placeholder="描述资源生成过程、资源拆解分析等，没有可不填"
                />
                <p class="ql-editor" v-else v-html="versionData.desc || '暂无内容'"></p>
              </div>
              <el-divider />
              <div>
                <h2>建议反馈</h2>
                <MyQuillEditor
                  v-if="editMode"
                  v-model="versionData.comment"
                  :dir="editorDirName"
                  placeholder="针对本次资源进行总结反馈"
                />
                <p class="ql-editor" v-else v-html="versionData.comment || '暂无内容'"></p>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-dialog title="创建新阶段" :visible.sync="createStageVisible" width="400px" :close-on-click-modal="false">
      <el-alert title="一个资源可能经历不同的阶段：参考->草稿->建模->动画A->动画B……，等" type="warning" :closable="false">
      </el-alert>
      <el-form :model="stageForm" ref="stageForm">
        <el-form-item label="阶段名称" prop="name" :rules="[{ required: true, message: '名称不能为空', trigger: 'blur' }]">
          <el-input v-model.trim="stageForm.name" placeholder="请输入阶段名称" maxlength="20" show-word-limit></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="createStageVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitStageForm">创 建</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<style lang="less">
.art-detail {
  h2 > small {
    font-size: 12px;
    color: #909399;
  }
  .header {
    display: flex;
    justify-content: space-between;
    h2 {
      display: flex;
      align-items: center;
      margin: 0;
      // margin-bottom: 20px;
    }
  }
  .top_fixed {
    position: fixed;
    top: 0;
    background-color: #fff;
    padding: 10px;
    z-index: 999;
    -moz-box-shadow: 3px 2px 6px #9c9c9c;
    -webkit-box-shadow: 3px 2px 6px #ccc;
  }
  .el-divider {
    margin: 15px 0;
  }
  .version-item {
    cursor: pointer;
  }
  .version-item:hover {
    color: #67c23a;
  }
  .version-operator {
    margin-bottom: 20px;
    margin-left: 20px;
  }
  .stage-operator {
    margin-bottom: 50px;
  }

  .content {
    border-left: 1px dashed #e6e6e6;
    padding-left: 20px;
    background-color: #f3fffa;

    .version-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .version-content {
      .el-card__body {
        text-align: center;
      }
      .scene-note {
        margin: 50px 0;
      }
    }

    .scene-note-form {
      border: 1px dashed #e6e6e6;
      padding-top: 10px;
    }

    .scene-card {
      display: inline-block;
      margin-right: 20px;
      margin-bottom: 10px;

      .el-image > .el-image__inner {
        max-height: 150px;
        width: initial;
        cursor: pointer;
      }

      .el-image:first-child {
        margin-right: 10px;
      }

      .scene-name {
        color: #cf9236;
        font-size: 14px;
      }

      .ql-editor {
        font-size: 13px;
        padding: 10px 0;
      }
    }
  }
}
</style>

<script>
import MyQuillEditor from "@/components/MyQuillEditor";
import { Storage } from "@/common/storage";
import TagSelect from "./TagSelect";

export default {
  name: "art-detail",
  components: {
    MyQuillEditor,
    TagSelect
  },
  data() {
    return {
      data: {},
      stageSelected: "",
      versionSelected: "",
      versionData: {},
      operatorFixed: false,
      editMode: false,
      editorDirName: "art",
      createStageVisible: false,
      stageForm: { name: "", resource: "" },
      sceneForm: { name: "", desc: "", imgs: [] }, // 编辑中的效果展示模型
      sceneImgsList: [],
      loginUser: Storage.getLoginUser(),
      alertLoading: false,
      previewImgList: []
    };
  },
  computed: {
    sceneNoteList() {
      let note = this.versionData.scene_note;
      if (!note) {
        return [];
      }
      note = JSON.parse(note);
      return note;
    },
    timelineType() {
      return function(id) {
        if (this.versionSelected == id) {
          return "success";
        }
        return "primary";
      };
    },
    readImg() {
      return function(img) {
        return this.$apis.readFile(img);
      };
    }
  },
  mounted() {
    this.getResource();
    // window.addEventListener("scroll", this.handleScroll, true); // 滚动监听
  },
  methods: {
    handleScroll(event) {
      let scrollTop = event.target.scrollTop; // 滚动条偏移量
      let $obj = document.querySelector(".box-fixed");
      let offsetTop = $obj ? $obj.offsetTop : 0;
      this.operatorFixed = scrollTop > offsetTop ? true : false; // 如果滚动到顶部了，this.isFixed就为true
    },
    deleteResource() {
      this.$confirm("报告将被删除, 是否继续?", "危险操作提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "danger"
      })
        .then(() => {
          this.$apis.deleteResource(this.$route.params.id).then(res => {
            this.$message.success("删除成功");
            this.$router.push(`/art`);
          });
        })
        .catch(() => {});
    },
    getResource() {
      this.$apis.getResource(this.$route.params.id).then(res => {
        this.data = res.data;
        let stageObjs = this.data.stage_objs;
        if (stageObjs.length > 0) {
          this.stageSelected = this.stageSelected && this.stageSelected != "0" ? this.stageSelected : stageObjs[0].id.toString();
        }
      });
    },
    getVersion(veriosnId) {
      this.previewImgList = [];
      this.$apis.getVersion(veriosnId).then(res => {
        this.versionData = res.data;
        let _list = this.sceneNoteList;
        for (let i = 0; i < _list.length; i++) {
          for (let j = 0; j < _list[i].imgs.length; j++) {
            this.previewImgList.push(this.$apis.readFile(_list[i].imgs[j]));
          }
        }
      });
    },
    getLatestVersion() {
      let latestVersion = "";
      let stageObjs = this.data.stage_objs;
      if (stageObjs) {
        for (let i = 0; i < stageObjs.length; i++) {
          if (stageObjs[i].id.toString() == this.stageSelected) {
            let versions = stageObjs[i].versions;
            if (versions.length > 0) {
              latestVersion = versions[0];
              break;
            }
          }
        }
      }
      return latestVersion;
    },
    versionSelectEvent(versionId) {
      this.versionSelected = versionId;
    },
    submitStageForm() {
      this.$refs["stageForm"].validate(valid => {
        if (valid) {
          this.stageForm["resource"] = this.$route.params.id;
          this.$apis.createStage(this.stageForm).then(res => {
            let data = res.data;
            this.$message.success("创建成功");
            this.createStageVisible = false;
            this.stageSelected = data.id;
            this.getResource();
          });
        }
      });
    },
    deleteStage() {
      this.$apis.deleteStage(this.stageSelected).then(res => {
        this.$message.success("删除成功");
        this.stageSelected = "";
        this.getResource();
      });
    },
    pictureUploadSuccess(response, file, fileList) {
      this.sceneForm.imgs.push(response.fileUrl);
    },
    pictureRemove(file, fileList) {
      let fileUrl = file.response ? file.response.fileUrl : file.fileUrl;
      let index = this.sceneForm.imgs.indexOf(fileUrl);
      if (index != -1) {
        this.sceneForm.imgs.splice(index, 1);
      }
    },
    buildSceneForm() {
      this.$refs["sceneForm"].validate(valid => {
        if (valid) {
          let created = true; // 是否新增一个
          let _list = this.sceneNoteList;
          if (_list) {
            for (let i = 0; i < _list.length; i++) {
              if (_list[i].name == this.sceneForm.name) {
                _list[i] = this.sceneForm;
                created = false; // 找到名字相同的替换；
                break;
              }
            }
          }
          if (created) {
            _list.push(this.sceneForm);
          }
          this.$set(this.versionData, "scene_note", JSON.stringify(_list)); // 属性动态生成时要set才会实时刷新
        }
      });
    },
    resetSceneForm() {
      if (this.$refs["sceneUpload"]) {
        this.$refs["sceneUpload"].clearFiles();
      }
      if (this.$refs["sceneForm"]) {
        this.$refs["sceneForm"].resetFields();
      }
      this.sceneForm.imgs = [];
      this.sceneImgsList = [];
    },
    editSceneEvent(sceneName) {
      this.sceneImgsList = [];
      let _list = this.sceneNoteList;
      for (let i = 0; i < _list.length; i++) {
        if (_list[i].name == sceneName) {
          let obj = { ..._list[i] }; // 防止双向绑定
          this.sceneForm = obj; // 防止双向绑定
          for (let j = 0; j < obj.imgs.length; j++) {
            this.sceneImgsList.push({ url: this.$apis.readFile(obj.imgs[j]), fileUrl: obj.imgs[j] });
          }
          break;
        }
      }
    },
    delSceneEvent(sceneName) {
      let _list = this.sceneNoteList;
      for (let i = 0; i < _list.length; i++) {
        if (_list[i].name == sceneName) {
          _list.splice(i--, 1);
          break;
        }
      }
      this.versionData.scene_note = JSON.stringify(_list);
    },
    saveVersion() {
      this.versionData["stage"] = this.stageSelected;
      this.versionData["updated_by"] = this.loginUser.id;
      // console.log(this.versionData);
      let id = this.versionData.id;
      if (!id) {
        // 创建
        this.$apis.createVersion(this.versionData).then(res => {
          let data = res.data;
          this.$message.success("保存成功");
          this.versionSelected = data.id;
          this.getResource();
          this.editMode = false;
        });
      } else {
        // 修改
        this.$apis.patchVersion(id, this.versionData).then(res => {
          let data = res.data;
          this.$message.success("修改成功");
          // this.getVersion(data.id);
          this.editMode = false;
        });
      }
    },
    deleteVersion() {
      this.$confirm("当前选中版本将被删除, 是否继续?", "危险操作提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "danger"
      })
        .then(() => {
          this.$apis.deleteVersion(this.versionSelected).then(res => {
            this.$message.success("删除成功");

            this.versionSelected = this.getLatestVersion();
            this.getResource();
          });
        })
        .catch(() => {});
    },
    alertVersion() {
      this.$confirm("即将发送当前版本报告提醒到群组，是否继续?", "操作提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "danger"
      })
        .then(() => {
          this.alertLoading = true;
          this.$apis
            .alertVersion(this.versionSelected)
            .then(res => {
              let data = res.data;
              this.$message.success("发送成功");
              this.alertLoading = false;
            })
            .catch(() => {
              this.$message.success("发送失败，请联系开发者");
              this.alertLoading = false;
            });
        })
        .catch(() => {});
    }
  },
  watch: {
    stageSelected: {
      handler: function(val, lastVal) {
        // 默认选中第一个版本
        this.versionData = {};
        let latestVersion = this.getLatestVersion();
        // console.log(val, lastVal);
        this.versionSelected = val == lastVal ? this.versionSelected : latestVersion;
        return val;
      }
    },
    versionSelected: {
      handler: function(val) {
        // 更新报告
        this.versionData = {};
        if (!val) {
          val = this.getLatestVersion();
        }
        if (val != "-1") {
          this.getVersion(val);
        }

        return val;
      }
    },
    // sceneForm: {
    //   handler: function(val) {
    //     console.log("sceneForm:", val.imgs);

    //     return val;
    //   },
    //   deep: true
    // },
    editMode: {
      handler: function(val) {
        if (!val) {
          // 退出编辑模式清空表单
          this.resetSceneForm(); // 清空表单
          this.versionData = {}; // 清空版本数据

          if (this.versionSelected && this.versionSelected != "-1") {
            this.getVersion(this.versionSelected);
          } else {
            this.versionSelected = this.getLatestVersion();
          }
        } else {
          // 进入编辑模式预览清空
          this.previewImgList = [];
        }

        return val;
      }
    }
  }
};
</script>
