<template>
  <div class="top-menu">
    <el-menu :default-active="this.$route.path" router>
      <!-- <el-menu-item index="/">
        <i class="el-icon-menu"></i>
        <span slot="title">首页</span>
      </el-menu-item> -->
      <el-menu-item index="/meeting">
        <i class="el-icon-chat-dot-square"></i>
        <span slot="title">周会</span>
      </el-menu-item>
      <el-menu-item index="/attend">
        <i class="el-icon-location"></i>
        <span slot="title">考勤</span>
      </el-menu-item>
      <!-- <el-menu-item index="/task">
        <i class="el-icon-date"></i>
        <span slot="title">任务</span>
      </el-menu-item> -->
      <el-menu-item index="/art">
        <i class="el-icon-document"></i>
        <span slot="title">美术</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<style lang="less" scoped>
.top-menu {
  height: 100%;

  .el-menu {
    height: 100%;
  }
}
</style>

<script>
export default {
  name: "top-menu",
  components: {},
  mounted() {},
  methods: {}
};
</script>
