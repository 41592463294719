import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/element.js";
import http from "./common/http/index";
import * as echarts from "echarts";
import highcharts from "./common/chart/index";
import moment from "moment";
import * as apis from "./common/http/apis";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import "./assets/styles/common.less";
import "./assets/styles/elementSelf.less";
import "./assets/styles/quillEditor.less";

Vue.config.productionTip = false;

Vue.prototype.$http = http;
Vue.prototype.$apis = apis;
Vue.prototype.$echarts = echarts;
Vue.prototype.$highcharts = highcharts;
Vue.prototype.$moment = moment;

Vue.filter("moment", function(dateStr: string, pattern = "YYYY-MM-DD") {
  return moment(dateStr).format(pattern);
});

Vue.filter("toFixed", function(num: number, digits = 2) {
  return num.toFixed(digits);
});

Vue.filter("replace", function(text: any, from = "", to = "") {
  return text.toString().replace(from, to);
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
