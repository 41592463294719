import Highcharts from "highcharts";

// Highcharts.chart({
//   time: {
//     useUTC: false
//   }
// });
Highcharts.setOptions({
  lang: {
    months: ["一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"],
    shortMonths: ["一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"],
    weekdays: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"]
  }
});

export default Highcharts;
