import { User } from "./models/User";

export class Storage {
  static jwtUserInfo = "jwtUserInfo";

  /**
   * 用户基础信息
   */
  static getLoginUser() {
    let jwtUserInfo = localStorage.getItem(this.jwtUserInfo);
    if (!jwtUserInfo) {
      return null;
    }
    let jwtInfo = JSON.parse(jwtUserInfo);
    return new User(jwtInfo);
  }
}
