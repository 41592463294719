<template>
  <div class="meeting-detail">
    <el-drawer :visible.sync="visible" :before-close="beforeCloseHandle" size="800px" v-loading="updateLoading">
      <template slot="title">
        <div class="drawer-header">
          <b>{{ title }}</b>
          <div v-show="!editMode">
            <el-button type="warning" size="mini" plain @click="updateMeetingCount">更新数据</el-button>
            <el-button type="primary" size="mini" icon="el-icon-edit" circle @click="editMeeting" title="编辑"></el-button>
            <el-button type="danger" size="mini" icon="el-icon-delete" circle @click="deleteMeeting" title="删除"></el-button>
          </div>
          <div v-show="editMode">
            <el-button type="primary" size="mini" icon="el-icon-check" circle @click="updateMeeting" title="保存"></el-button>
            <el-button size="mini" icon="el-icon-close" circle @click="editMode = false" title="取消"></el-button>
          </div>
        </div>
      </template>
      <div class="drawer-content">
        <div>
          <h3>打卡汇总</h3>
          <el-table :data="attendList" border size="mini" stripe>
            <el-table-column prop="user" label="" width="90px">
              <template slot-scope="scope">
                <el-avatar size="small" :src="scope.row.user.avatar"></el-avatar>
                <span>{{ scope.row.user.first_name }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="clockinSum" label="打卡总时长">
              <template slot-scope="scope">
                <TrendTableColumn :value="scope.row.clockinSum" :trend="scope.row.clockinSumTrend" unit="h" />
              </template>
            </el-table-column>
            <el-table-column prop="address" label="打卡平均时长">
              <template slot-scope="scope">
                <TrendTableColumn :value="scope.row.clockinAvg" :trend="scope.row.clockinAvgTrend" unit="h" />
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div>
          <h3>任务汇总</h3>
          <el-table :data="taskList" border size="mini" stripe>
            <el-table-column prop="user" label="" width="90px">
              <template slot-scope="scope">
                <el-avatar size="small" :src="scope.row.user.avatar"></el-avatar>
                <span>{{ scope.row.user.first_name }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="completed" label="本周完成" width="250px">
              <template slot-scope="scope">
                <b v-if="scope.row.completed.length == 0" class="text-danger">本周无已完成任务！！</b>
                <div v-for="item in scope.row.completed" :key="item.taskId">
                  <i class="text-success el-icon-success"></i>
                  <small class="project text-success" v-show="item.project">【{{ item.project }}】</small>
                  {{ item.title }}
                  <div v-for="(sub, index) in item.subs" :key="index" class="sub-tasks">
                    <small v-if="sub.length > 0">{{ index + 1 }}. {{ sub }}</small>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="durantion" label="总耗时" width="100px">
              <template slot-scope="scope">
                <TrendTableColumn :value="scope.row.durationSum" :trend="scope.row.durationSumTrend" unit="h" />
              </template>
            </el-table-column>
            <el-table-column prop="proceed" label="计划任务">
              <template slot-scope="scope">
                <b v-if="scope.row.proceed.length == 0" class="text-danger">暂无下一步计划！</b>
                <div v-for="item in scope.row.proceed" :key="item.task_id">
                  <i class="text-warning el-icon-s-claim"></i>
                  <small class="project text-success" v-show="item.project">【{{ item.project }}】</small>
                  {{ item.title }}
                  <el-tag v-if="item.state == '打开'" size="mini" type="warning">{{ item.state }}</el-tag>
                  <el-tag v-else size="mini" type="info">{{ item.state }}</el-tag>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="">
          <h3>
            议程
          </h3>
          <div class="ql-editor" v-show="!editMode" v-html="meeting.agenda || '暂无议程'"></div>
          <MyQuillEditor v-show="editMode" v-model="meeting.agenda" :dir="editorDirName" />
        </div>
        <div>
          <h3>会议记录</h3>
          <div class="ql-editor" v-show="!editMode" v-html="meeting.meeting_summary || '暂无记录'"></div>
          <MyQuillEditor v-show="editMode" v-model="meeting.meeting_summary" :dir="editorDirName" />
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<style lang="less">
.meeting-detail {
  .el-drawer__body {
    overflow-y: auto;
  }
  .el-drawer__container ::-webkit-scrollbar {
    display: none;
  }
  .sub-tasks {
    margin-left: 30px;
    font-size: 10px;
    color: #999;
  }
  .drawer-header {
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
  }
}
</style>

<script>
import TrendTableColumn from "@/components/TrendTableColumn";
import MyQuillEditor from "@/components/MyQuillEditor";

export default {
  name: "meeting-detail",
  components: {
    TrendTableColumn,
    MyQuillEditor
  },
  props: {
    data: {
      type: Object,
      default: function() {
        return {};
      }
    },
    visible: { type: Boolean, default: false }
  },
  data() {
    return {
      title: "",
      meeting: {},
      userDict: {},
      attendList: [],
      taskList: [],
      updateLoading: false,
      editMode: false,
      editorDirName: "week"
    };
  },
  created() {
    this.$apis.getUsers().then(res => {
      const data = res.data;
      for (let i = 0; i < data.length; i++) {
        this.userDict[data[i].id] = data[i];
      }
    });
  },
  mounted() {},
  methods: {
    beforeCloseHandle(done) {
      this.$emit("update:visible", false);
    },
    buildAttendList(item) {
      let result = [];
      for (const userId in item) {
        result.push({
          user: this.userDict[userId],
          clockinSum: item[userId].clockin_sum,
          clockinSumTrend: item[userId].clockin_sum_trend,
          clockinAvg: item[userId].clockin_avg,
          clockinAvgTrend: item[userId].clockin_avg_trend
        });
        // if (Object.hasOwnProperty.call(object, key)) {
        //   const element = object[key];
        // }
      }
      return result;
    },
    buildTaskList(item) {
      let result = []; // completeList/durantion/proceed
      for (const userId in item) {
        let duration = 0;
        let completeList = [];
        let completedDict = {};
        const completed = item[userId].completed;
        for (let i = 0; i < completed.length; i++) {
          if (Object.hasOwnProperty.call(completedDict, completed[i].task_id)) {
            completedDict[completed[i].task_id].push(completed[i]);
          } else {
            completedDict[completed[i].task_id] = [completed[i]];
          }
        }
        for (const taskId in completedDict) {
          let subs = [];
          for (let i = 0; i < completedDict[taskId].length; i++) {
            subs.push(completedDict[taskId][i].desc);
          }
          completeList.push({
            taskId: taskId,
            title: completedDict[taskId][0].title,
            project: completedDict[taskId][0].project,
            subs: subs
          });
        }

        result.push({
          user: this.userDict[userId],
          durationSum: item[userId].duration_sum,
          durationSumTrend: item[userId].duration_sum_trend,
          completed: completeList,
          proceed: item[userId].proceed
        });
        // if (Object.hasOwnProperty.call(object, key)) {
        //   const element = object[key];
        // }
      }
      return result;
    },
    trendFormatter(count, trend, unit = "") {
      let textClass = "text-success";
      let icon = "el-icon-top";
      if (trend < 0) {
        textClass = "text-danger";
        icon = "el-icon-bottom";
      }
      count = count.toFixed(0);
      trend = trend.toFixed(0).replaceAll("-", "");
      return (
        <span>
          <b>
            {count} {unit}{" "}
          </b>
          <small>
            <span class={textClass}>
              <i class={icon}></i>
              {trend}
            </span>
          </small>
        </span>
      );
    },
    clockinFormatter(row) {
      return this.trendFormatter(row.sum_summary_obj.clockin_sum, row.sum_summary_obj.clockin_sum_trend, "h");
    },
    clockinAvgFormatter(row) {
      return this.trendFormatter(row.sum_summary_obj.clockin_avg, row.sum_summary_obj.clockin_avg_trend, "h");
    },
    durationFormatter(row) {
      return this.trendFormatter(row.sum_summary_obj.duration_sum, row.sum_summary_obj.duration_sum_trend, "h");
    },

    /**
     * 获取报告
     */
    getMeeting() {
      this.$apis.getMeeting(this.data.id).then(res => {
        this.meeting = res.data;
      });
    },

    /**
     * 更新汇总数据
     */
    updateMeetingCount() {
      this.$confirm("汇总数据将更新，其中计划任务将更新为当前（非该报告时间范围内）未完成的任务单, 是否继续?", "危险操作提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "danger"
      })
        .then(() => {
          this.updateMeeting(true);
        })
        .catch(() => {});
    },

    /**
     * 更新报告
     */
    updateMeeting(needUpdateData = false) {
      this.updateLoading = true;
      this.$apis
        .patchMeeting(this.meeting.id, {
          agenda: this.meeting.agenda,
          meeting_summary: this.meeting.meeting_summary,
          needUpdateData: needUpdateData
        })
        .then(() => {
          this.$message.success("更新成功");
          this.updateLoading = false;
          this.getMeeting();
          this.editMode = false;
        });
    },

    /**
     * 删除报告
     */
    deleteMeeting() {
      this.$confirm("即将删除该报告, 是否继续?", "危险操作提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "danger"
      })
        .then(() => {
          this.$apis.deleteMeeting(this.meeting.id).then(() => {
            this.$message({ type: "success", message: "删除成功!" });
            this.beforeCloseHandle();
          });
        })
        .catch(() => {});
    },
    /**
     * 编辑报告
     */
    editMeeting() {
      this.editMode = true;
    }
  },
  watch: {
    data: {
      handler: function(val) {
        this.meeting = val;
        return val;
      },
      deep: true
    },
    meeting: {
      handler: function(val) {
        if (!val) {
          return val;
        }
        let startOn = this.$moment(val.start_on).format("M-D");
        let endOn = this.$moment(val.end_on).format("M-D");
        this.title = `每周报告 ${startOn} ～ ${endOn}`;
        this.attendList = this.buildAttendList(val.attend_summary_obj);
        this.taskList = this.buildTaskList(val.work_summary_obj);
        // console.log(this.taskList);
        return val;
      },
      deep: true
    }
  }
};
</script>
