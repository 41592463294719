<template>
  <div class="tag-select">
    <el-form :inline="true" v-if="editMode">
      <el-form-item>
        <el-select
          size="mini"
          ref="tagsSelected"
          v-model="tagsSelected"
          multiple
          filterable
          allow-create
          default-first-option
          placeholder="请选择资源标签"
        >
          <el-option v-for="item in tagList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="success" size="mini" plain circle @click="onSubmit" icon="el-icon-check" title="保存"></el-button>
        <el-button
          type="default"
          size="mini"
          plain
          circle
          @click="editMode = false"
          icon="el-icon-close"
          title="取消"
        ></el-button>
      </el-form-item>
    </el-form>
    <div class="tags-display" v-else>
      <el-tag size="small" type="danger" v-for="item in selected" :key="item.id">{{ item.name }}</el-tag>
      <small style="font-size:12px;" v-show="selected.length == 0" class="text-tip">无标签</small>
      <el-button type="text" size="mini" @click="editMode = true">编辑</el-button>
    </div>
  </div>
</template>

<style lang="less">
.tag-select {
  .el-form-item {
    margin-bottom: 0;
  }

  .tags-display {
    .el-tag {
      margin-right: 10px;
    }
  }
}
</style>

<script>
export default {
  name: "tag-select",
  components: {},
  props: {
    resourceId: { type: Number, require: true },
    data: {
      type: Array,
      default: () => {
        return [];
      }
    },
    selected: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      tagList: [],
      tagsSelected: [],
      editMode: false
    };
  },
  model: {
    prop: "selected",
    event: "change"
  },
  computed: {},
  mounted() {
    this.getTags();
  },
  methods: {
    getTags() {
      this.$apis.getTags().then(res => {
        let data = res.data;
        this.tagList = data;
      });
    },
    async onSubmit() {
      for (let i = 0; i < this.tagsSelected.length; i++) {
        if (typeof this.tagsSelected[i] == "number") {
          continue;
        }
        await this.$apis.createTag({ name: this.tagsSelected[i] }).then(res => {
          const data = res.data;
          this.tagList.push(data);
          this.tagsSelected[i] = data.id;
        });
      }
      this.$apis.patchResource(this.resourceId, { tags: this.tagsSelected }).then(res => {
        let data = res.data;
        // this.$message.success("保存成功！");
        this.$emit("change", data.tag_objs);
        // console.log(this.selected);
        this.editMode = false;
      });
    }
  },
  watch: {
    selected: {
      handler: function(val) {
        this.tagsSelected = [];
        if (val) {
          for (let i = 0; i < val.length; i++) {
            const element = val[i];
            this.tagsSelected.push(val[i].id);
          }
        }
        return val;
      },
      immediate: true
    }
  }
};
</script>
