/**
 * axios处理http请求
 */
import axios from "axios";
import { Message } from "element-ui";
import { getHeaders } from "./cookies";

axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || "";
// axios.defaults.headers.common["Authorization"] = "localStorage.getItem(Storage.jwtUserInfo)";
axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";

const config = {
  baseURL: process.env.baseURL || process.env.apiUrl || "",
  timeout: 60 * 1000, // Timeout
  withCredentials: true // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    config.headers = getHeaders();
    if (config.method == "post" || config.method == "patch" || config.method == "delete") {
      config.headers["Content-Type"] = "application/x-www-form-urlencoded";
    }

    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    // Do something with response error
    if (error.response) {
      console.log(error.response);
      switch (error.response.status) {
        case 401:
          Message.error("用户未登录");
          break;
        case 403:
          Message.error("权限不足:" + (error.response.data.detail || ""));
          break;
        default:
          Message.error("服务器异常:" + (error.response.data.detail || ""));
          break;
      }
    }
    return Promise.reject(error);
  }
);

export default _axios;
