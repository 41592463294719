<template>
  <div class="home">
    <el-container>
      <el-aside width="120px">
        <LoginInfo />
        <TopMenu />
      </el-aside>
      <el-main>
        <router-view />
        <el-backtop target=".el-main"></el-backtop>
      </el-main>
      <!-- <el-container direction="vertical">
        <el-header height="40px">
          <div></div>
        </el-header>
        <el-main>
          <router-view />
          <el-backtop target=".el-main"></el-backtop>
        </el-main>
      </el-container> -->
    </el-container>
  </div>
</template>

<style lang="less" scoped>
.home,
.el-container {
  height: 100%;
}
.el-header {
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>

<script>
// @ is an alias to /src
import TopMenu from "@/components/TopMenu.vue";
import LoginInfo from "@/components/LoginInfo.vue";

export default {
  name: "Home",
  components: {
    TopMenu,
    LoginInfo
  }
};
</script>
