<template>
  <div class="art-card">
    <el-card shadow="hover" @click.native="onCardClick(data.id)">
      <div slot="header" class="header">
        <b>
          {{ data.name }}
        </b>
        <el-avatar size="small" :src="data.created_by_obj.avatar"></el-avatar>
        <!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
      </div>
      <div class="body">
        <div class="content" v-if="data.latest_stage">
          <el-image v-for="(image, i) in imageList" :key="i" :src="image"></el-image>
        </div>
        <div class="footer">
          <div class="tags">
            <el-tag size="mini" type="primary" v-if="data.latest_stage">{{ data.latest_stage.name }}</el-tag>
            <el-tag size="mini" type="danger" v-for="item in data.tag_objs" :key="item.id">{{ item.name }}</el-tag>
          </div>
          <div class="update-info" v-if="data.latest_stage && data.latest_stage.latest_version">
            <el-avatar size="small" :src="data.latest_stage.latest_version.updated_by_obj.avatar"></el-avatar>
            <small class="time">更新于 {{ data.latest_stage.latest_version.updated_on | moment("Y-M-D HH:mm:ss") }}</small>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<style lang="less">
.art-card {
  cursor: pointer;
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 20px;

  .el-avatar--small {
    width: 20px;
    height: 20px;
  }

  .el-card__header {
    padding: 10px;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .stage {
        color: teal;
      }
    }
  }
  .el-card__body {
    padding: 10px;
  }

  .content {
    .el-image__inner {
      margin: 5px;
      height: 100px;
      width: initial;
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    .update-info {
      align-items: center;
      display: flex;
      .time {
        color: #666;
      }
    }

    .tags {
      .el-tag {
        margin-right: 5px;
      }
    }
  }
}
</style>

<script>
export default {
  name: "art-card",
  components: {},
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      maxPreImageLength: 4 // 最大图片数
    };
  },
  computed: {
    imageList() {
      let latest_version = this.data.latest_stage.latest_version;
      if (!latest_version) {
        return [];
      }
      let result = [];
      let sceneNote = latest_version.scene_note ? JSON.parse(latest_version.scene_note) || [] : [];
      for (let i = 0; i < sceneNote.length; i++) {
        if (sceneNote[i].imgs.length > 0) {
          result.push(this.$apis.readFile(sceneNote[i].imgs[0]));
          if (result.length >= this.maxPreImageLength) {
            break;
          }
        }
      }
      return result;
    }
  },
  mounted() {},
  methods: {
    onCardClick(itemId) {
      this.$router.push(`/art/${itemId}`);
    }
  }
};
</script>
