<template>
  <div class="meeting-create">
    <el-dialog title="创建报告" :visible.sync="visible" :before-close="beforeCloseHandle">
      <div>
        <el-date-picker
          v-model="daterange"
          type="daterange"
          align="right"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          :editable="false"
          :clearable="false"
        >
        </el-date-picker>
      </div>
      <div>
        <div class="">
          <h3>
            议程
          </h3>
          <MyQuillEditor v-model="agenda" :dir="editorDirName" />
        </div>
        <div>
          <h3>会议记录</h3>
          <MyQuillEditor v-model="meeting_summary" :dir="editorDirName" />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" :loading="createLoading" @click="createMeeting">生成报告</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<style lang="less"></style>

<script>
import MyQuillEditor from "@/components/MyQuillEditor";

export default {
  name: "meeting-create",
  components: {
    MyQuillEditor
  },
  props: {
    visible: { type: Boolean, default: false }
  },
  data() {
    return {
      editorDirName: "week",
      agenda: "",
      meeting_summary: "",
      daterange: [this.$moment().subtract(8, "days"), this.$moment().subtract(1, "days")],
      pickerOptions: {
        firstDayOfWeek: 1,
        disabledDate(time) {
          // 大于当前日期不能选
          return time.getTime() >= new Date();
        },
        shortcuts: [
          {
            text: "最近一周",
            onClick: picker => {
              picker.$emit("pick", [this.$moment().subtract(7, "days"), this.$moment()]);
            }
          },
          {
            text: "当月",
            onClick: picker => {
              picker.$emit("pick", [this.$moment().startOf("month"), this.$moment()]);
            }
          },
          {
            text: "上一个月",
            onClick: picker => {
              picker.$emit("pick", [
                this.$moment()
                  .subtract(1, "months")
                  .startOf("month"),
                this.$moment()
                  .subtract(1, "months")
                  .endOf("month")
              ]);
            }
          },
          {
            text: "今年",
            onClick: picker => {
              picker.$emit("pick", [this.$moment().startOf("year"), this.$moment()]);
            }
          }
        ]
      },
      createLoading: false
    };
  },
  created() {},
  mounted() {},
  methods: {
    beforeCloseHandle(done) {
      this.$emit("update:visible", false);
    },
    createMeeting() {
      let start = this.$moment(this.daterange[0]).format("YYYY-MM-DD");
      let end = this.$moment(this.daterange[1]).format("YYYY-MM-DD");
      console.log(start, end, this.agenda, this.meeting_summary);
      this.createLoading = true;
      this.$apis.createMeeting(start, end, this.agenda, this.meeting_summary).then(res => {
        this.createLoading = false;
        this.$message.success("生成成功！");
        this.beforeCloseHandle();
      });
    }
  },
  watch: {}
};
</script>
