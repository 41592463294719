<template>
  <div>
    <b>{{ value | toFixed(1) }} {{ unit }} </b>
    <small :class="trendClass"><i :class="iconClass"></i>{{ trendText }}</small>
  </div>
</template>

<style lang="less" scoped></style>

<script>
export default {
  name: "trend-table-column",
  components: {},
  props: {
    value: { type: Number, default: 0 },
    trend: { type: Number, default: 0 },
    unit: { type: String, default: "" }
  },
  data() {
    return {};
  },
  computed: {
    trendClass: function() {
      // console.log(this.trend, this.trend > 0.1);
      if (this.trend > 0.1) {
        return "text-success";
      } else if (this.trend < -0.1) {
        return "text-danger";
      } else {
        return "text-tip";
      }
    },
    iconClass: function() {
      if (this.trend > 0.1) {
        return "el-icon-top";
      } else if (this.trend < -0.1) {
        return "el-icon-bottom";
      } else {
        return "el-icon-minus";
      }
    },
    trendText: function() {
      if (this.trend > 0.1) {
        return this.trend.toFixed(1);
      } else if (this.trend < -0.1) {
        return this.trend.toFixed(1).replace("-", "");
      } else {
        return "";
      }
    }
  },
  mounted() {},
  methods: {}
};
</script>
