<template>
  <div class="meeting">
    <div class="header">
      <small>任务工时数据每周五上午5:00定时导入，生成周报告请先确认数据是否完整</small>
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-size="pageSize"
        layout="total, prev, pager, next"
        :total="totalCount"
      >
      </el-pagination>
    </div>
    <el-table :data="meetingList" style="width: 100%" stripe>
      <el-table-column type="index" width="50"></el-table-column>
      <el-table-column align="center" width="200px">
        <template slot="header">
          <el-button type="success" size="mini" plain @click="createVisible = true">新建周报告</el-button>
        </template>
        <template slot-scope="scope">
          <span>每周报告 {{ scope.row.start_on | moment("M-D") }} ～ {{ scope.row.end_on | moment("M-D") }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="sum_summary_obj" width="120px" label="打卡总时长">
        <template slot-scope="scope">
          <TrendTableColumn
            :value="scope.row.sum_summary_obj.clockin_sum"
            :trend="scope.row.sum_summary_obj.clockin_sum_trend"
            unit="h"
          />
        </template>
      </el-table-column>
      <el-table-column prop="sum_summary_obj" width="120px" label="打卡平均时长">
        <template slot-scope="scope">
          <TrendTableColumn
            :value="scope.row.sum_summary_obj.clockin_avg"
            :trend="scope.row.sum_summary_obj.clockin_avg_trend"
            unit="h"
          />
        </template>
      </el-table-column>
      <el-table-column prop="sum_summary_obj" width="120px" label="任务总耗时">
        <template slot-scope="scope">
          <TrendTableColumn
            :value="scope.row.sum_summary_obj.duration_sum"
            :trend="scope.row.sum_summary_obj.duration_sum_trend"
            unit="h"
          />
        </template>
      </el-table-column>
      <el-table-column prop="sum_summary_obj" width="120px" label="任务有效率">
        <template slot-scope="scope">
          <TrendTableColumn
            :value="scope.row.sum_summary_obj.effective * 100"
            :trend="scope.row.sum_summary_obj.effective_trend * 100"
            unit="%"
          />
        </template>
      </el-table-column>
      <el-table-column prop="agenda" label="议程">
        <template slot-scope="scope">
          <div class="ql-editor" v-html="scope.row.agenda || '无'"></div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150px">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" plain icon="el-icon-search" @click="showMeetingDetail(scope.row)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Detail :data="activeMeeting" :visible.sync="detailVisible" />
    <Create :visible.sync="createVisible" />
  </div>
</template>

<style lang="less">
.meeting {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .el-collapse-item__header.is-active {
    color: teal;
  }
  .operators {
    margin-left: 20px;
  }
}
</style>

<script>
import Detail from "./Detail";
import Create from "./Create";
import TrendTableColumn from "@/components/TrendTableColumn";

export default {
  name: "meeting",
  components: {
    Detail,
    Create,
    TrendTableColumn
  },
  data() {
    return {
      meetingList: [],
      activeMeeting: {},
      detailVisible: false,
      createVisible: false,
      userDict: {},
      currentPage: 1,
      pageSize: 15,
      totalCount: 0
    };
  },
  mounted() {
    this.getMeetings();
  },
  methods: {
    getMeetings() {
      this.$apis.getMeetings(this.currentPage, this.pageSize).then(res => {
        const data = res.data;
        this.meetingList = data.data;
        this.totalCount = data.totalCount;
      });
    },
    trendFormatter(count, trend, unit = "") {
      let textClass = "text-success";
      let icon = "el-icon-top";
      if (trend < 0) {
        textClass = "text-danger";
        icon = "el-icon-bottom";
      }
      count = count.toFixed(0);
      trend = trend.toFixed(0).replaceAll("-", "");
      return (
        <span>
          <b>
            {count} {unit}{" "}
          </b>
          <small>
            <span class={textClass}>
              <i class={icon}></i>
              {trend}
            </span>
          </small>
        </span>
      );
    },
    clockinFormatter(row) {
      return this.trendFormatter(row.sum_summary_obj.clockin_sum, row.sum_summary_obj.clockin_sum_trend, "h");
    },
    clockinAvgFormatter(row) {
      return this.trendFormatter(row.sum_summary_obj.clockin_avg, row.sum_summary_obj.clockin_avg_trend, "h");
    },
    durationFormatter(row) {
      return this.trendFormatter(row.sum_summary_obj.duration_sum, row.sum_summary_obj.duration_sum_trend, "h");
    },
    effectiveFormatter(row) {
      return this.trendFormatter(row.sum_summary_obj.effective * 100, row.sum_summary_obj.effective_trend * 100, "%");
    },

    /**
     * 查看报告详情
     */
    showMeetingDetail(row) {
      this.activeMeeting = row;
      this.detailVisible = true;
    },

    /**
     * 翻页
     */
    handleCurrentChange(val) {
      this.getMeetings();
    }
  },
  watch: {
    detailVisible: {
      handler: function(val) {
        if (!val) {
          this.getMeetings();
        }
        return val;
      }
    },
    createVisible: {
      handler: function(val) {
        if (!val) {
          this.getMeetings();
        }
        return val;
      }
    }
  }
};
</script>
