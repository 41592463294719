<template>
  <div>
    <quill-editor
      ref="myQuillEditor"
      class="editor"
      :style="{ height: height }"
      v-model="content"
      :options="editorOption"
      @blur="onEditorBlur($event)"
      @focus="onEditorFocus($event)"
      @ready="onEditorReady($event)"
    />
  </div>
</template>

<style lang="less"></style>

<script>
import { quillEditor, Quill } from "vue-quill-editor";
import { container, ImageExtend, QuillWatch } from "quill-image-super-solution-module";
import { getHeaders } from "@/common/http/cookies";
Quill.register("modules/ImageExtend", ImageExtend);
const Keyboard = Quill.import("modules/keyboard");

export default {
  name: "my-quill-editor",
  components: {
    quillEditor
  },
  props: {
    text: { type: String, default: "" },
    dir: { type: String, default: "editors" },
    height: { type: String, default: "100%" },
    saveHandle: { type: Function, default: function() {} },
    placeholder: { type: String, default: "请输入正文" }
  },
  data() {
    return {
      mode: "normal", // 模式：normal、simple
      // 富文本编辑器默认内容
      content: "",
      //富文本编辑器配置
      editorOption: {
        scrollingContainer: "ql-editor",
        modules: {
          // 图片上传服务器，默认为base64的，action设置了值就按action的上传
          ImageExtend: {
            loading: true,
            name: "file",
            size: 5,
            action: "/api/upload/file/",
            accept: "image/jpg, image/png, image/gif, image/jpeg, image/bmp, image/x-icon",
            response: res => {
              // console.log(res);
              return this.$apis.readFile(res.fileUrl);
            },
            change: (xhr, formData) => {
              // 后台登录的话可以直接上传，暂时先注释掉，有问题再说
              // let headers = getHeaders();
              // for (const k in headers) {
              //   xhr.setRequestHeader(k, headers[k]);
              // }
              formData.append("dir", this.dir);
            },
            sizeError: () => {
              return this.$message.error("图片超过5M");
            } // 图片超过大小的回调
          },
          //工具栏
          toolbar: {
            container: [
              // [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题-----[{ header: [1, 2, 3, 4, 5, 6, false] }]
              // [{ size: ["small", false, "large", "huge"] }], // 字体大小-----[{ size: ['small', false, 'large', 'huge'] }]
              // [{ font: [] }], // 字体种类-----[{ font: [] }]
              ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线 -----['bold', 'italic', 'underline', 'strike']
              [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色-----[{ color: [] }, { background: [] }]
              // [{ header: 1 }, { header: 2 }], // 1、2 级标题-----[{ header: 1 }, { header: 2 }]
              [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表-----[{ list: 'ordered' }, { list: 'bullet' }]
              // [{ script: "sub" }, { script: "super" }], // 上标/下标-----[{ script: 'sub' }, { script: 'super' }]
              // [{ indent: "-1" }, { indent: "+1" }], // 缩进-----[{ indent: '-1' }, { indent: '+1' }]
              // [{ direction: "rtl" }], // 文本方向-----[{'direction': 'rtl'}]
              [{ align: [] }], // 对齐方式-----[{ align: [] }]
              ["blockquote", "code-block"], // 引用  代码块-----['blockquote', 'code-block']
              // ["clean"], // 清除文本格式-----['clean']
              ["link", "image", "video"] // 链接、图片、视频-----['link', 'image', 'video']
            ],
            handlers: {
              image: function() {
                QuillWatch.emit(this.quill.id);
              }
            }
          }
        },
        //主题
        theme: "snow",
        placeholder: this.placeholder
      }
    };
  },
  model: {
    prop: "text",
    event: "change"
  },
  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill;
    }
  },
  mounted() {
    this.editor.keyboard.addBinding({ key: "S", ctrlKey: true }, this.handleSaveKeyBind); // window/linux
    this.editor.keyboard.addBinding({ key: "S", metaKey: true }, this.handleSaveKeyBind); // mac
  },
  methods: {
    //失去焦点事件
    onEditorBlur(quill) {
      // console.log("editor blur!", quill);
    },
    //获得焦点事件
    onEditorFocus(quill) {
      // console.log("editor focus!", quill);
    },
    // 准备富文本编辑器
    onEditorReady(quill) {
      // console.log("editor ready!", quill);
    },
    // 保存快捷键处理
    handleSaveKeyBind(range, context) {
      this.saveHandle(range, context, this.content);
    }
  },
  watch: {
    text: {
      handler: function(val) {
        this.content = val;
        return val;
      },
      immediate: true
    },
    content: {
      handler: function(val) {
        this.$emit("change", val);
        return val;
      },
      immediate: true
    }
  }
};
</script>
