import { Storage } from "../../common/storage";

export function getCookie(name: string) {
  let cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    let c = cookies[i].trim();
    if (c.indexOf(name) > -1) {
      return c.substring(name.length + 1, c.length);
    }
  }
  return "";
}

export function getHeaders() {
  let headers = {};
  let jwtUserInfo = localStorage.getItem(Storage.jwtUserInfo);
  if (jwtUserInfo) {
    headers = {
      Authorization: JSON.parse(jwtUserInfo).jwt_token,
      "X-CSRFToken": getCookie("csrftoken")
    };
    // console.log(headers);
  }
  return headers;
}
