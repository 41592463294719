<template>
  <div class="login-info text-center">
    <span v-if="!loginUser">退出登录</span>
    <el-dropdown trigger="click" @command="handleCommand" v-else>
      <span class="el-dropdown-link">
        <el-avatar size="small" :src="loginUser.avatar"></el-avatar>
        <small>{{ loginUser.username }}</small>
        <i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item disabled>{{ loginUser.firstName }}</el-dropdown-item>
        <el-dropdown-item command="logout" divided>退出登录</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<style lang="less" scoped>
.login-info {
  border-right: 1px solid #e6e6e6;
}
</style>

<script>
import { Storage } from "@/common/storage";
import { logout } from "@/common/http/apis";
export default {
  name: "login-info",
  components: {},
  data() {
    return {
      loginUser: Storage.getLoginUser()
    };
  },
  mounted() {},
  methods: {
    handleCommand(command) {
      if (command == "logout") {
        logout().then(() => {
          location.href = "/";
        });
      }
    }
  }
};
</script>
